import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useGrowthBook } from '@growthbook/growthbook-react';

import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import useResourceMutation from 'dpl/shared/fetching/hooks/useResourceMutation';
import { analyticsReset } from 'dpl/util/analytics';
import {
  currentUserQueryDefinition,
  currentUserLoginMutationDefinition,
  currentUserLogoutMutationDefinition
} from 'dpl/shared/hooks/queryDefinitions';

export default function useCurrentUser() {
  const query = useResourceQuery(currentUserQueryDefinition);
  const { queryKey, isError, isFetching, isSuccess, value, refetch } = query;

  const { mutateAsync: login } = useResourceMutation({
    ...currentUserLoginMutationDefinition,
    queryKey
  });
  const { mutateAsync: logoutResource } = useResourceMutation({
    ...currentUserLogoutMutationDefinition
  });

  const queryClient = useQueryClient();

  async function logout() {
    const ret = await logoutResource();
    analyticsReset();
    return ret;
  }

  let isLoggedIn = null;
  let isBreeder = null;
  let isBuyer = null;
  if (isError || (isSuccess && !value.data)) {
    isLoggedIn = false;
  } else if (isSuccess) {
    isLoggedIn = true;
    isBreeder = value.data.breeder;
    isBuyer = value.data.buyer;

    if (window.bugsnagClient) {
      window.bugsnagClient.user = {
        ...window.bugsnagClient.user,
        ...value.data
      };
    }

    const analyticsDefaultProps =
      window.__GD_ANALYTICS_TRACK_DEFAULT_PROPS || {};

    if (analyticsDefaultProps.user_id !== value.data.user_id) {
      const primaryPhone = value.data.phone_numbers_attributes.find(
        phone => phone.primary
      );

      window.__GD_ANALYTICS_TRACK_DEFAULT_PROPS = {
        ...analyticsDefaultProps,
        user_email: value.data.email,
        user_first_name: value.data.first_name,
        user_id: value.data.user_id,
        user_last_name: value.data.last_name,
        user_phone: primaryPhone?.number ?? null
      };
    }
  }

  const growthbook = useGrowthBook();
  const growthbookReady = growthbook?.ready;

  useEffect(() => {
    if (!growthbookReady || !value?.data?.user_id) {
      return;
    }

    growthbook.setAttributes({
      ...growthbook.getAttributes(),
      user_id: `${value.data.user_id}`
    });
  }, [isLoggedIn, growthbookReady]);

  return {
    isBreeder,
    isBuyer,
    isFetching,
    isLoggedIn,
    login,
    logout,
    invalidate: () => queryClient.invalidateQueries(queryKey),
    value: value || {},
    fetch: refetch, // backwards-compatibility
    refetch
  };
}
